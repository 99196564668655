
import dayjs from "dayjs";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import StepList from "@/components/scope/step-list.vue";
import Template from "@/views/dashboard/template.vue";
const finance = namespace("finance");
const base = namespace("base");
@Component({
  components: { Template, StepList }
})
@Table("loadListData")
export default class InvoiceManage extends Vue {
  @finance.Action getFinanceWithdrawalList;
  @base.Action getDictionaryList;
  @finance.Action getFundSummary;
  @finance.Action platformRecharge;
  @finance.Action queryPlatformRechargeList;


  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  searchForm = {
    receiptTimeFrom: "",
    receiptTimeTo: "",
    payerName: "",
    receiptNo: "",
    receiptType: ""
  };
  dialogFormVisible: boolean = false;
  dialogIntegralFormVisible: boolean = false;

  fundSummary = {}
  fundIntegralSummary = {}

  receiptList: any[] = [];
  receiptTypeList: any[] = [];

  rechargeForm= {
    outerOrderNo: "",
    amount: 0,
    rechargeType: 1
  }

  rechargeDrawer: boolean = false;
  rechargeIntegralDrawer: boolean = false;


  rechargeCurrentPage:any = 1;
  rechargePageSize:any = 10;
  rechargeList:any = [];
  rechargeTotal: any = 0;


  //积分信息
  rechargeIntegralCurrentPage:any = 1;
  rechargeIntegralPageSize:any = 10;
  rechargeIntegralList:any = [];
  rechargeIntegralTotal: any = 0;


  get searchList() {
    return [
      {
        label: "用户手机号",
        type: "input",
        value: "",
        prop: "mobile"
      },
      {
        label: "商家批次号",
        type: "input",
        value: "",
        prop: "batchNo"
      },
      {
        label: "提现单号",
        type: "input",
        value: "",
        prop: "orderId"
      },
      {
        label: "提现状态",
        type: "select",
        selectList: this.receiptTypeList,
        value: "",
        prop: "status"
      }
    ];
  }

  created() {
    this.queryFundSummary()
    this.queryIntegralFundSummary()
    this.init();
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "receiptTime") {
          this.searchForm.receiptTimeFrom = item.value ? item.value[0] : "";
          this.searchForm.receiptTimeTo = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getFinanceWithdrawalList({
      orderItems: this.mix_sortParams,
      ...this.searchForm,
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum
    }).then(data => {
      this.receiptList = this.$safeValue(data, "data.list", []);
      return data;
    });
  }

  init() {
    this.loadListData();
    this.getDictionaryList("withdrawal_status").then(data => {
      this.receiptTypeList = data;
    });
  }

  queryIntegralFundSummary(){
    this.getFundSummary({
      rechargeType: 2
    }).then(res => {
      this.fundIntegralSummary = res.data
    });
  }

  queryFundSummary(){
    this.getFundSummary({
      rechargeType: 1
    }).then(res => {
      this.fundSummary = res.data
    });
  }

  rechargeDialog(){
    this.dialogFormVisible = true;
  }

  rechargeIntegralDialog(){
    this.dialogIntegralFormVisible = true;
  }

  recharge(rechargeType){
    if(this.rechargeForm.amount <=0){
      if(rechargeType === 1){
        this.$message.error("充值金额必须大于0")
      }
      if(rechargeType === 2){
        this.$message.error("充值积分必须大于0")
      }
      return
    }
    this.rechargeForm.rechargeType = rechargeType;
    this.platformRecharge(this.rechargeForm).then(resp =>{
      if(resp.code === "0"){
        this.$message.success("充值成功");
        this.queryFundSummary()
        this.queryIntegralFundSummary();
        this.dialogFormVisible = false
        this.dialogIntegralFormVisible = false;
        this.rechargeForm.amount = 0
        this.rechargeForm.outerOrderNo = ''
        return
      }
      this.$message.error(resp.message)
    })
  }

  cancelDialogFormVisible(){
    this.dialogFormVisible = false
    this.rechargeForm.amount = 0
    this.rechargeForm.outerOrderNo = ''
  }

  cancelIntegralDialogFormVisible(){
    this.dialogIntegralFormVisible = false
    this.rechargeForm.amount = 0
    this.rechargeForm.outerOrderNo = ''
  }

  handleUserView(row) {
    let { href } = this.$router.resolve({
      path: `/customer/customer-view/${row.mobile}`
    });
    window.open(href, "_blank");
  }

  rechargeDrawerHandle(){
    this.rechargeDrawer = true;
    this.loadRechargeListData();
  }

  rechargeIntegralDrawerHandle(){
    this.rechargeIntegralDrawer = true;
    this.loadIntegralRechargeListData();
  }

  handleRechargeClose(){
    this.rechargeDrawer = false;
  }

  handleIntegralRechargeClose(){
    this.rechargeIntegralDrawer = false;
  }

  loadRechargeListData() {
    this.queryPlatformRechargeList({
      pageNum: this.rechargeCurrentPage,
      pageSize: this.rechargePageSize,
      rechargeType: 1
    }).then(data => {
      this.rechargeTotal = data.data.total
      this.rechargeList = data.data.list
    });
  }

  loadIntegralRechargeListData() {
    this.queryPlatformRechargeList({
      pageNum: this.rechargeCurrentPage,
      pageSize: this.rechargePageSize,
      rechargeType:2
    }).then(data => {
      this.rechargeIntegralTotal = data.data.total
      this.rechargeIntegralList = data.data.list
    });
  }

  rechargeHandleSizeChange(size){
    this.rechargeCurrentPage = size
    this.loadRechargeListData()
  }

  rechargeHandlePageChange(pageNo){
    this.rechargeCurrentPage = pageNo
    this.loadRechargeListData()
  }

  rechargeIntegralHandleSizeChange(size){
    this.rechargeIntegralCurrentPage = size
    this.loadIntegralRechargeListData()
  }

  rechargeIntegralHandlePageChange(pageNo){
    this.rechargeIntegralCurrentPage = pageNo
    this.loadIntegralRechargeListData();
  }
  // -----------------------------
}
